import React from "react"
import {
  Box,
  Heading,
  SimpleGrid,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import { GiRake, GiHighGrass, GiBrickPile, GiMineTruck } from "react-icons/gi"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import LandingLayout from "../../components/LandingLayout"
import Meta from "../../components/Meta"
import { CTAButton } from "../../components/landingpage/CTAButton"
import { Feature2 } from "../../components/landingpage/Feature2"
import { Testimonial } from "../../components/landingpage/Testimonial"
import { StatCard } from "../../components/landingpage/StatCard"
import AccordionFAQ from "../../components/landingpage/AccordionFAQ"
import LeadForm from "../../components/forms/LeadForm"

const Home = ({ data }) => {
  const questions = [
    {
      id: "q1",
      heading: "Step 1: Trim bushes and perennials/annuals",
      text:
        "We start by trimming all your bushes, perennials, and annuals to make sure your landscaping looks great.",
    },
    {
      id: "q2",
      heading: "Step 2: Blow out landscape beds and curb lines",
      text:
        "Next we throw on our backpack blower and make sure your landscape beds and curb lines are clean.",
    },
    {
      id: "q3",
      heading: "Step 3: Clean up Leaves",
      text:
        "Now we clean up all the leaves, sticks, and debris from your yard.",
    },
    {
      id: "q3",
      heading: "Step 4: Mowing and Edging",
      text:
        "At this point your lawn is ready to be mowed and edged for the last time of the season.",
    },
    {
      id: "q4",
      heading: "Step 4: Haul away debris",
      text:
        "The last step is to haul away all debris from your yard leaving it looking great.",
    },
  ]

  return (
    <LandingLayout>
      <Meta
        title="Sioux Falls Fall Cleanup"
        description="Bullseye Lawn Care ony has limited Fall cleanup spots remaining! Learn how you can get your lawn ready for the Winter season with a Bullseye Lawn Care Fall Cleanup."
      />
      <Box
        as="section"
        bg={useColorModeValue("gray.50", "gray.800")}
        pb="24"
        pos="relative"
        px={{ base: "6", lg: "12" }}
      >
        <Box maxW="7xl" mx="auto">
          <Box
            maxW={{ lg: "md", xl: "xl" }}
            pt={{ base: "20", lg: "40" }}
            pb={{ base: "16", lg: "24" }}
          >
            <Heading
              as="h1"
              size="3xl"
              fontWeight="extrabold"
              color={useColorModeValue(
                "bullseyeOrange.500",
                "bullseyeOrange.400"
              )}
            >
              Are the falling leaves making your lawn look a little messy?
            </Heading>
            <Text
              color={useColorModeValue("gray.600", "gray.400")}
              my="5"
              fontSize="xl"
            >
              Now that the leaves are falling you may have noticed that your
              lawn is leaving a little bit to be desired. A Fall Cleanup will
              revitalize your yard and get it ready for the Winter season.
              Contact us now as we only have a few 2022 Fall Cleanup spots spots
              remaining!
            </Text>
            <CTAButton
              w={{ base: "full", md: "auto" }}
              bg="bullseyeGreen.600"
              _hover={{ bg: "bullseyeGreen.400" }}
              color="white"
            >
              Book your Fall Cleanup
            </CTAButton>
          </Box>
        </Box>
        <Box
          pos={{ lg: "absolute" }}
          insetY={{ lg: "0" }}
          insetEnd={{ lg: "0" }}
          bg="gray.50"
          w={{ base: "full", lg: "50%" }}
          height={{ base: "full", lg: "full" }}
          sx={{
            clipPath: { lg: "polygon(8% 0%, 100% 0%, 100% 100%, 0% 100%)" },
          }}
        >
          <StaticImage
            src="../../images/small-house-falling-leaves.jpg"
            alt="House with Falling Leaves"
            placeholder="blurred"
            width="1200"
            height="1200"
          />
        </Box>
      </Box>
      <Box as="section" pb="24">
        <Box bg="bullseyeGreen.600" color="white" pt="24" pb="12rem">
          <Box
            maxW={{ base: "xl", md: "7xl" }}
            mx="auto"
            px={{ base: "6", md: "8" }}
          >
            <Stack
              spacing="10"
              direction={{ base: "column", lg: "row" }}
              align={{ base: "flex-start", lg: "center" }}
              justify="space-between"
            >
              <Heading
                size="2xl"
                lineHeight="short"
                fontWeight="extrabold"
                maxW={{ base: "unset", lg: "800px" }}
              >
                A Fall Cleanup is exactly what your lawn needs!
              </Heading>
              <CTAButton w={{ base: "full", md: "auto" }}>
                Book Your Fall Cleanup
              </CTAButton>
            </Stack>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 4 }}
              spacing={{ base: "12", md: "8", lg: "2" }}
              mt={{ base: "12", md: "20" }}
            >
              <Feature2 icon={<GiRake />} title="Landscape Trimming">
                Bushes and annuals/perennials are trimmed to get everything
                ready for the Winter season.
              </Feature2>
              <Feature2 icon={<GiHighGrass />} title="Lawn Cleanup">
                Leaves and sticks are cleaned up and removed from your property
                leaving it looking clean.
              </Feature2>
              <Feature2 icon={<GiBrickPile />} title="Landscape Cleanup">
                Landscaping is blown out and weeds are removed to leave your
                landscaping looking great.
              </Feature2>
              <Feature2 icon={<GiMineTruck />} title="Debris Removal">
                We haul all the lawn debris to the dump for you leaving your
                yard spotless.
              </Feature2>
            </SimpleGrid>
          </Box>
        </Box>
        <Box mt="-24">
          <Box
            maxW={{ base: "xl", md: "7xl" }}
            mx="auto"
            px={{ base: "6", md: "8" }}
          >
            <SimpleGrid spacing="14" columns={{ base: 1, lg: 2 }}>
              <Testimonial
                image={data.BillTestimonial.childImageSharp.gatsbyImageData}
                name="Bill"
                role="Residential Customer"
              >
                Yard looks amazing! Never been so clean. Thank you!
              </Testimonial>
              <Testimonial
                image={data.EmilyTestimonial.childImageSharp.gatsbyImageData}
                name="Emily"
                role="Residential Customer"
              >
                Thank You! Our lawn looks fantastic. We are thrilled with the
                work you did and will be in touch when we are ready to
                landscape.
              </Testimonial>
            </SimpleGrid>
          </Box>
        </Box>
      </Box>
      <Box as="section" py="24">
        <Box
          maxW={{ base: "xl", md: "7xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
        >
          <Flex
            direction={{ base: "column", lg: "row" }}
            justify="space-between"
          >
            <Box flex="1" maxW={{ lg: "xl" }}>
              <StaticImage
                src="../../images/rakecleanup.jpg"
                alt="Rake with lawn debris"
                placeholder="blurred"
                aspectRatio={1}
              />
            </Box>
            <Box
              ms={{ lg: "12" }}
              mt={{ base: "12", lg: 0 }}
              flex="1"
              maxW={{ lg: "xl" }}
            >
              <Box maxW={{ base: "xl", lg: "unset" }}>
                <Box
                  as="b"
                  color={useColorModeValue(
                    "bullseyeGreen.500",
                    "bullseyeGreen.300"
                  )}
                >
                  Get the best looking lawn on the block
                </Box>
                <Heading
                  mt="4"
                  mb="5"
                  size="2xl"
                  fontWeight="extrabold"
                  lineHeight="1.2"
                >
                  What exactly is a Fall Cleanup?
                </Heading>
                <Text
                  fontSize="lg"
                  color={useColorModeValue("gray.600", "gray.400")}
                >
                  You might be wondering what Bullseye Lawn Care does for our
                  Fall cleanups and if it's right for you and your lawn.
                </Text>
              </Box>
              <AccordionFAQ items={questions} mt={{ base: "5", lg: "5" }} />
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box as="section" pb="15" bg={useColorModeValue("gray.50", "gray.700")}>
        <Box bg="bullseyeGreen.600" pt="20" pb="20">
          <Box
            maxW={{ base: "xl", md: "7xl" }}
            mx="auto"
            px={{ base: "6", md: "8" }}
          >
            <Stack
              spacing="10"
              direction={{ base: "column", lg: "row" }}
              align="flex-start"
              justify="space-between"
            >
              <Box mb="16" color="white" maxW="xl">
                <Heading
                  size="2xl"
                  letterSpacing="tight"
                  fontWeight="extrabold"
                  lineHeight="normal"
                >
                  Why is a Fall cleanup important?
                </Heading>
                <Text fontSize="lg" mt="4" fontWeight="medium">
                  You might think you can "get by" without a proper Fall
                  cleanup. Here are some helpful reasons why a quality Fall
                  clean-up can help your lawn.
                </Text>
              </Box>
              <Box mb="16" pt="4" color="white" maxW="xl">
                <CTAButton w={{ base: "full", md: "auto" }}>
                  Book Your Spring Cleanup
                </CTAButton>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box mt="-20">
          <Box
            maxW={{ base: "xl", md: "7xl" }}
            mx="auto"
            px={{ base: "6", md: "8" }}
          >
            <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} spacing="6">
              <StatCard title="Landscape" value="Trimming">
                Will keep your perennial/annual beds healthy and ready to grow
                in the Spring.
              </StatCard>
              <StatCard title="Leaves and Sticks" value="Removal">
                Leaves left on the lawn over the Winter makes healthy growth in
                the Spring more difficult and increases the likilihood of lawn
                diseases.
              </StatCard>
              <StatCard title="Neighborhood" value="Curb Appeal">
                By removing debris and blowing out the entire yard, your lawn
                will be the talk of the block.
              </StatCard>
            </SimpleGrid>
          </Box>
        </Box>
      </Box>

      <Box
        as="section"
        bg={useColorModeValue("gray.50", "gray.700")}
        id="lead-form"
        pt="10"
      >
        <Box
          maxW="3xl"
          mx="auto"
          px={{ base: "6", lg: "8" }}
          py={{ base: "16", sm: "20" }}
          textAlign="center"
        >
          <Box>
            <Heading
              color={useColorModeValue(
                "bullseyeGreen.600",
                "bullseyeGreen.400"
              )}
              as="h1"
              size="2xl"
              fontWeight="extrabold"
              letterSpacing="tight"
            >
              Book Your Fall Cleanup
            </Heading>
            <Text
              mt="3"
              fontSize={{ base: "xl", md: "2xl" }}
              fontWeight="bold"
              color={useColorModeValue("gray.500", "inherit")}
            >
              Ready to make your neighbors jealous? Get a free Fall Cleanup
              quote using the form below.
            </Text>
          </Box>

          <Box
            minW={{ md: "420px" }}
            maxW="xl"
            mt="10"
            mx="auto"
            rounded="xl"
            bg={{ md: useColorModeValue("white", "gray.600") }}
            shadow={{ md: "lg" }}
            px={{ md: "10" }}
            pt={{ base: "8", md: "12" }}
            pb="8"
          >
            <LeadForm />
          </Box>
        </Box>
      </Box>
    </LandingLayout>
  )
}

export const pageQuery = graphql`
  query {
    BillTestimonial: file(relativePath: { eq: "BillTestimonial.png" }) {
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 1
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    EmilyTestimonial: file(relativePath: { eq: "EmilyTestimonial.png" }) {
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 1
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`

export default Home
